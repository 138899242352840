/*
*
* Tooltop Custom
*/

//
// Base class
//
.tooltip {
  font-size: $font-size-base;
  line-height: 1.2;
}