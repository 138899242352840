/*
*
* Links
*/

// Standard link style
a {
  transition: $transition-base;
  
  &,
  &:focus,
  &:active,
  &:hover {
    text-decoration: $link-decoration;
  }

  @include link($link-color, $link-hover-color);

  &[href*='callto'],
  &[href*='mailto'] {
    white-space: nowrap;
  }
}

.link-hover {
  color: $link-hover-color;
}

.link-press {
  color: $link-press-color;
}

.privacy-link { }

* + .privacy-link { margin-top: 25px; }