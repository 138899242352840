/*
*
* Footers
*/

//
// Footer Classic
//

.footer-classic {
  padding: 5px 0;
  background: rgba($black, .32);
  color: $white;
  font-size: 16px; 
  margin-top: auto;
  
}

.footer-group {
  text-align: center;
  > * {
    margin-top: 0;
    padding: 10px;
  }
  // Medium ≥768px
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
}