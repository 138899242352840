// modal

.modal-backdrop {
  display: none;
}

.modal {
  //top: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 100vh;
  
  // Large ≥992px
  @include media-breakpoint-up(lg) {
    bottom: auto;
    height: auto;
    transition: .3s ease-in-out;
  }
  .modal-dialog {
    max-width: 100%;
    margin: 0;
  }

  .modal-content {
    background: transparent;
    border: none;
  }
  
  &.slideUp {
    opacity: 0;
    transform: translateY(100px);
    
    
    &.show {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  [data-x-mode] & {
    position: relative;
    display: block;
    opacity: 1;
    visibility: visible;
    transform: none;
  }
  
  .ie-11 &,
  .ie-edge & {
    position: absolute;

    .modal-content {
      display: block;
      justify-content: center;
    }
  }
}

.hide-on-modal {
  position: relative;
}
.hide {
  opacity: 0;
  visibility: hidden;
  transition: .4s ease-in-out;
  transform: translateY(-100px);
}

.modal.modal-centered.show {
  padding-top: 10px;
  //top: 25px;
  // Large ≥992px 
  @include media-breakpoint-up(md) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#privacy {
  [data-x-mode] & {
    background: rgba($black, .32);
  }
}