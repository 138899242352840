/*
*
* Icons
*/

//
// Base styles
//
.icon {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  transition: .3s ease-in-out; 
 
  &::before {
    position: relative;
    display: inline-block; 
    font-weight: 400;
    font-style: normal;
    speak: none; 
    text-transform: none;
  }
}

//
// Alternate icons
//

.icon-default {
}

.icon-primary {
  color: $primary;
}
.icon-secondary {
  color: $secondary;
}

//
// Icon Shapes
//
.icon-circle {
  border-radius: 50%;
}

//
// Icon Sizes
//
.icon-xs {
}

.icon-sm {
}

.icon-lg {
  font-size: 26px;
  // Extra large ≥1200px
  @include media-breakpoint-up(xl) {
    font-size: 38px; 
  } 
}