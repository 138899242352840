/*
*
* Backgrounds
*/

.context-dark {
  @include context-dark;
}

%context-dark {
  @include context-dark;
 
  // Logo
  .brand {
    .brand-logo-dark { display: none; }
    .brand-logo-light { display: block; }
  }
}

%context-light {
  @include context-light;

  // Logo
  .brand {
    .brand-logo-dark { display: block; }
    .brand-logo-light { display: none; }
  }
}

/**
* Light Backgrounds
*/
.bg-default {
  @include bg-behaviour($body-bg); 
}

.bg-gray-100 {
  @include bg-behaviour($gray-100);
}


/**
* Dark Backgrounds
*/
.bg-gray-dark {
  @extend %context-dark;
  @include bg-behaviour($gray-700);
}


/** 
* Accent Backgrounds
*/
.bg-accent {
  @extend %context-dark;
  & + & { padding-top: 0; }
}


/**
* Background Image
*/
.bg-image {
  
}

[class^='bg-'] {
  background-size: cover;
  background-position: center center;
}

// Desktop only
//
html:not(.tablet):not(.mobile) {
  .bg-fixed {
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
  }
}