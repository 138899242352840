/*
* Element groups
*/

html {
  .group {
    @include group(15px, 20px);
  } 
  
  .group-sm {
    @include group(10px, 10px);
  }
  
  .group-xl {
    @include group(30px, 20px);
    
    @include media-breakpoint-up(lg) {
      @include group-media(45px, 20px);
    }
  }
  
  .group-sm-justify {
    .button {
      padding-left: 20px;
      padding-right: 20px;
    }
    
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: center;
      
      > * {
        flex-grow: 1;
        flex-shrink: 0;
      }
      
      .button {
        min-width: 130px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  
  // Mods
  .group-middle { 
    display: inline-table;
    vertical-align: middle;
    
    > * {
      vertical-align: middle;
    }
  }
}

* + .group-sm { margin-top: 30px; }
* + .group-xl { margin-top: 20px; }