.lg-css3 {
    // Remove all transition effects
    &.lg-no-trans {
        .lg-prev-slide, .lg-next-slide, .lg-current {
            @include transitionCustom(none 0s ease 0s !important);
        }
    }

    &.lg-use-css3 {
        .lg-item {
            will-change: transform, opacity;
        }
    }

    &.lg-use-left {
        .lg-item {
            will-change: left, opacity;
        }
    }

    &.lg-zoom-in {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include scale3d(2, 2, 2);
            }

            &.lg-next-slide {
                @include scale3d(2, 2, 2);
            }

            &.lg-current {
                @include scale3d(1, 1, 1);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-zoom-out {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include scale3d(.7, .7, .7);
            }

            &.lg-next-slide {
                @include scale3d(.7, .7, .7);
            }

            &.lg-current {
                @include scale3d(1, 1, 1);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1.5s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 2s ease 0s);
            }
        }
    }

    &.lg-zoom-out-in {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include scale3d(0, 0, 0);
            }

            &.lg-next-slide {
                @include scale3d(2, 2, 2);
            }

            &.lg-current {
                @include scale3d(1, 1, 1);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-zoom-in-out {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include scale3d(2, 2, 2);
            }

            &.lg-next-slide {
                @include scale3d(0, 0, 0);
            }

            &.lg-current {
                @include scale3d(1, 1, 1);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-soft-zoom {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include scale3d(1.2, 1.2, 1.2);
            }

            &.lg-next-slide {
                @include scale3d(0.8, 0.8, 0.8);
            }

            &.lg-current {
                @include scale3d(1, 1, 1);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-slide-circular {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0, 0, 0) translate3d(-100%, 0, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0, 0, 0) translate3d(100%, 0, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    // sec
    &.lg-slide-circular-up {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0, 0, 0) translate3d(-100%, -100%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0, 0, 0) translate3d(100%, -100%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    // sec
    &.lg-slide-circular-down {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0, 0, 0) translate3d(-100%, 100%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0, 0, 0) translate3d(100%, 100%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-slide-circular-vertical {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0, 0, 0) translate3d(0, -100%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0, 0, 0) translate3d(0, 100%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    // sec
    &.lg-slide-circular-vertical-left {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0, 0, 0) translate3d(-100%, -100%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0, 0, 0) translate3d(-100%, 100%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    // sec
    &.lg-slide-circular-vertical-down {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0, 0, 0) translate3d(100%, -100%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0, 0, 0) translate3d(100%, 100%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-slide-vertical {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include translate3d(0, -100%, 0);
            }

            &.lg-next-slide {
                @include translate3d(0, 100%, 0);
            }

            &.lg-current {
                @include translate3d(0, 0, 0);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-vertical-growth {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-only {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(60deg, 0deg));
            }

            &.lg-next-slide {
                @include transform(skew(60deg, 0deg));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-only-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(-60deg, 0deg));
            }

            &.lg-next-slide {
                @include transform(skew(-60deg, 0deg));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-only-y {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(0deg, 60deg));
            }

            &.lg-next-slide {
                @include transform(skew(0deg, 60deg));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-only-y-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(0deg, -60deg));
            }

            &.lg-next-slide {
                @include transform(skew(0deg, -60deg));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(60deg, 0deg) translate3d(-100%, 0%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(60deg, 0deg) translate3d(100%, 0%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(-60deg, 0deg) translate3d(-100%, 0%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(-60deg, 0deg) translate3d(100%, 0%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-cross {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(0deg, 60deg) translate3d(-100%, 0%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(0deg, 60deg) translate3d(100%, 0%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-cross-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(0deg, -60deg) translate3d(-100%, 0%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(0deg, -60deg) translate3d(100%, 0%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-ver {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(60deg, 0deg) translate3d(0, -100%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(60deg, 0deg) translate3d(0, 100%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-ver-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(-60deg, 0deg) translate3d(0, -100%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(-60deg, 0deg) translate3d(0, 100%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-ver-cross {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(0deg, 60deg) translate3d(0, -100%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(0deg, 60deg) translate3d(0, 100%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-ver-cross-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(0deg, -60deg) translate3d(0, -100%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(0deg, -60deg) translate3d(0, 100%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-lollipop {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include translate3d(-100%, 0, 0);
            }

            &.lg-next-slide {
                @include transform(translate3d(0, 0, 0) scale(0.5));
            }

            &.lg-current {
                @include translate3d(0, 0, 0);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-lollipop-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(translate3d(0, 0, 0) scale(0.5));
            }

            &.lg-next-slide {
                @include translate3d(100%, 0, 0);
            }

            &.lg-current {
                @include translate3d(0, 0, 0);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-rotate {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(rotate(-360deg));
            }

            &.lg-next-slide {
                @include transform(rotate(360deg));
            }

            &.lg-current {
                @include transform(rotate(0deg));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-rotate-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(rotate(360deg));
            }

            &.lg-next-slide {
                @include transform(rotate(-360deg));
            }

            &.lg-current {
                @include transform(rotate(0deg));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-tube {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(1, 0, 1) translate3d(-100%, 0, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(1, 0, 1) translate3d(100%, 0, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }
}