/*
*
* Typography
*/

//
// Body
//
body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-weight: $font-weight-base;
  color: $body-color;
  background-color: $body-bg;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  overflow-x: hidden;
}

//
// Headings
//
#{headings()} {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: $headings-font-weight;
  color: $headings-color;
  //text-transform: uppercase;

  a {
    color: inherit;
    
    &:hover {
      color: $primary;
    }
  }
}

h1,
.heading-1 {
  font-size: 32px;
  line-height: 1.5;

  @include media-breakpoint-up(xl) {
    font-size: $h1-font-size;
    line-height: $h1-line-height;
  }
}

h2,
.heading-2 {
  font-size: 28px;
  line-height: 1.5;

  @include media-breakpoint-up(xl) {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
  }
}

h3,
.heading-3 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 300;

  @include media-breakpoint-up(xl) {
    font-size: $h3-font-size;
    line-height: $h3-line-height;
  }
}

h4,
.heading-4 {
  font-size: 22px;
  line-height: 1.5; 

  @include media-breakpoint-up(xl) {
    font-size: $h4-font-size;
    line-height: $h4-line-height;
  } 
}

h5,
.heading-5 {
  font-size: 20px;
  line-height: 1.5;

  @include media-breakpoint-up(xl) {
    font-size: $h5-font-size;
    line-height: $h5-line-height;
  }
}

h6,
.heading-6 {
  font-size: 18px;
  line-height: 1.5;

  @include media-breakpoint-up(xl) {
    font-size: $h6-font-size;
    line-height: $h6-line-height;
  }
}

.subtitle {
  text-transform: uppercase;
  color: $white;
  font-weight: 600;
  line-height: 1.4;
}

//
// Emphasis
//
small,
.small {
  display: block;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
}

mark,
.mark {
  padding: 3px 5px;
  color: $white-invariable;
  background: $primary;
}

// Big text
.big {
  font-size: $font-size-lg;
  line-height: $line-height-lg;
}

.lead {
  font-size: $lead-font-size;
  line-height: $lead-line-height;
  font-weight: $lead-font-weight;
}

code {
  padding: $code-padding-y $code-padding-x;
  border-radius: $border-radius-sm;
  font-size: $code-font-size;
  color: $code-color;
  background: $code-bg;
}


//
// Paragraph
//
p {
  [data-toggle='tooltip'] {
    padding-left: .25em;
    padding-right: .25em;
    color: $primary;
  }
  
  [style*='max-width'] {
    display: inline-block;
  }
}

.content-box {
  position: relative;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  //border-radius: 35px;
  background-color: $gray-900;
  
  .close-content-box,
  .close-modal-content-box {
    position: absolute;
    top: -10px;
    right: -10px;
    
    display: inline-block;
    text-align: center;
    width: 43px;
    height: 43px;
    font-size: 24px;
    background-color: $primary;
    line-height: 38px;
    
    &:hover{
      background-color: $white;
      color: $black;  
    }
  }
  
  // Large ≥992px
  @include media-breakpoint-up(xl) {
    padding: 50px;
    //border-radius: 85px;
    max-width: 970px;
    
    &-inner {
      padding-left: 15px;
      padding-right: 15px;
      max-height: 470px; 
      overflow-y: auto;
    }
    .close-content-box,
    .close-modal-content-box {
      top: 4px;
      right: 4px;
    }
  }
}


// service box

.service-box {
  .icon {
    color: $white;
  }
}

//contact info

.contact-info {
  li {
    padding: 2px 0;
    span {
      display: inline-block;
      min-width: 75px; 
    }
  }
}

