/*
*
* Blocks
*/

%block-center {
  margin-left: auto;
  margin-right: auto;
}

// The block element can be centered horizontally
//
.block-center {
  @extend %block-center;
}

// Limits block width and block element can be centered horizontally
//
.block-sm {
  @extend %block-center;
  max-width: 560px;
}

.block-lg {
  @extend %block-center;
  max-width: 768px;
}

// Block center
.block-center {
  padding: 10px;
  &:hover {
    .block-center-header {
      background-color: $primary;
    }
  }
}

.block-center-inner {
}

.block-center-inner-aside {
}

.block-center-inner-main {
}

.block-center-title {
  background-color: $white;
}

@include media-breakpoint-down(xl) {
  .block-center {
    padding: 20px;
    &:hover {
      .block-center-header {
        background-color: $white;
      }
    }
  }
  
  .block-center-header {
    background-color: $primary;
  }
}

 






