/*
*
* Posts
*/

// 
// Table of Contents:
// 
// Post Classic
//
//

//
// Post Classic
//
.post-classic {
  
}

// Context styling
%context-dark {
  .post-classic {
    
  }
}