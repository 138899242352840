/*
*
* Headers
*/

.page-header {
  //display: flex;
  //align-items: center;
  //justify-content: center; 

  padding: 40px 0;
  
  // Extra large ≥1200px
  @include media-breakpoint-up(xl) {
    padding: 60px 0 30px; 
  } 
}

.brand-logo {
  text-align: left;
  display: inline-block;
  &:hover{
    color: $white;
  }
}
.brand-name {
  display: block;
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 100;
  line-height: 1;
  
  // Extra large ≥1200px
  @include media-breakpoint-up(xl) {
    font-size: 45px; 
  } 
}

.brand-subtitle {
  text-transform: uppercase;
  letter-spacing: .12em;
  opacity: .75;
  padding-left: 3px;
  
  // Extra large ≥1200px
  @include media-breakpoint-up(xl) {
    letter-spacing: .29em; 
  } 
}

.head-title {
  font-size: 24px;
  line-height: 1.2;
  color: $white;
  font-weight: $headings-font-weight;
  // Large ≥992px
  @include media-breakpoint-up(lg) {
    
  }
}