/*
*
* Utilities custom
*/
.height-fill {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > * {
    flex-grow: 1;
    flex-shrink: 0;
  }
}

// Object inline 
.object-inline {
  white-space: nowrap;
  > * {
    display: inline-block;
    min-width: 20px;
    vertical-align: top;
    white-space: normal;
  }

  > * + * {  margin-left: 5px; }
}

.oh {
  position: relative;
  overflow: hidden;
}

// Text decorations
.text-decoration-lines {
  position: relative;
  overflow: hidden;
  width: 100%;

  &-content {
    position: relative;
    display: inline-block;
    min-width: 170px;
    font-size: 13px;
    text-transform: uppercase;

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      background: $gray-200;
      top: 50%;
      width: 100vw;
    }

    &::before {
      left: 0;
      transform: translate3d(-100%, 0, 0);
    }

    &::after {
      right: 0;
      transform: translate3d(100%, 0, 0);
    }
  }
}

* + .text-decoration-lines { margin-top: 30px; }

// Rights
p.rights {
  color: inherit;
  a {  
    color: inherit;
    
    &:hover {
      color: $primary;
    } 
  }
}

// JSColor Workaround
[style*='z-index: 1000;'] {
  z-index: $zindex-layout-panel + 1 !important;
}

.text-block {
  > * {
    margin-left: .125em;
    margin-right: .125em;
  }
}

.fade {
  transform: translate3d(0, -40px, 0);
  transform-origin: center;
  transition: .4s ease-in-out;

  &.show {
    transform: translate3d(0, 0, 0);
  }
}