/*
*
* RD Google Map
*/

$map-height: 200px;
$map-xs-height: 250px;
$map-sm-height: 300px;
$map-lg-height: 450px;
$map-xl-height: 370px;

.google-map-markers{
  display: none;
}

.google-map-container-wrap {
  //overflow: hidden;
  //border-radius: 45px;
  //will-change: transform;
}
.google-map-container {
  width: 100%;
}
.google-map {
  height: $map-height;
  width: 100%;
  
  // Medium ≥768px
  @include media-breakpoint-up(md) {
    height: $map-sm-height; 
  }

  // Extra large ≥1200px
  @include media-breakpoint-up(xl) {
    height: $map-xl-height; 
  } 
}
