//
// Nav custom
// 

// 
// Table of Contents:
// 
// Tabs Line 
// Tabs Corporate
//

// Corporate style
$tabs-corporate-color: $white;
$tabs-corporate-background: transparent;
$tabs-corporate-active-color: $white;
$tabs-corporate-active-background: transparent;
$tabs-corporate-border: #e5e7e9;

// Base styles
.tabs-custom {
  text-align: left;
  .nav-tabs {
    font-size: 0;
    line-height: 0;
    word-spacing: 0;
    border: 0;

    &:before,
    &:after {
      display: none;
    }
  }

  .nav-item {
    float: none;
    border: 0;
    cursor: pointer;
    transition: .33s all ease;
  }

  .nav-link {
    margin: 0;
    border-radius: 0;
    border: 0;
  }

  .nav-link.active {
    cursor: default;
    border: 0;
  }
}

.tab-content > .tab-pane {
  display: block;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  position: relative;
}

.tab-content > .active {
  visibility: visible;
  height: auto;
  overflow: visible;
}

// Offsets
* + .tabs-horizontal.tabs-corporate {
  margin-top: 25px;
}

* + .tabs-vertical.tabs-corporate {
  margin-top: 40px;
}

@include media-breakpoint-up(md) {
  * + .tabs-vertical.tabs-corporate {
    margin-top: 60px;
  }
}

@include media-breakpoint-up(xl) {
  * + .tabs-vertical.tabs-corporate {
    margin-top: 80px;
  }
}

// Tabs line
.tabs-line {
  .nav-tabs {
    position: relative;
  }

  .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    vertical-align: middle;
  }

  * + .tab-content {
    margin-top: 20px;
  }

  @include media-breakpoint-down(md) {
    .nav-tabs {
      max-width: 100%;
      border: 1px solid $tabs-corporate-border;
    }

    .nav-item {
      margin: -1px;
    }

    .nav-link {
      padding: 7px 10px;
      color: $tabs-corporate-color;
      background: $tabs-corporate-background;
      border-bottom: 1px solid $tabs-corporate-border;

      &:first-child {
        border-top: 1px solid $tabs-corporate-border;
      }
    }

    .nav-link:hover,
    .nav-link.active {
      color: $tabs-corporate-active-color;
      background: $tabs-corporate-active-background;
      //border-color: $primary;
    }
  }
}

// Tabs corporate style
.tabs-corporate {
  > .nav-tabs {
    display: block;
    position: relative;
    border: 1px solid;
  }

  .nav-item {
    margin: -1px;
    //display: block;
  }

  .nav-link {
    position: relative;
    padding: 10px 10px;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-size: 18px;
    line-height: 1;
    color: $tabs-corporate-color;
    text-align: center;
    vertical-align: middle;
    opacity: .87;
  }

  .nav-link-hover {
    display: none;
  }
  
  .nav-link:hover,
  .nav-link.active {
    color: $gray-700;
    background: $white;
  }

  .tab-content {
    position: relative;
    padding: 30px 0 0;
  }
}


@include media-breakpoint-up(md) {
  //
  // Horizontal tabs
  // 
  .tabs-horizontal {
    &.tabs-corporate {
      > .nav-tabs {
        position: relative;
        width: 100%;
        display: block;
        text-align: center;
        border: 0;
        will-change: transform;
        border-top: 1px solid $tabs-corporate-border;
      }

      .nav-item {
        display: inline-block;
        will-change: transform;
      }

      > .nav-tabs .nav-link {
        display: block;
        position: relative;
        z-index: 1;
        min-width: 130px;
        padding: 35px 20px;

        &,
        &::before {
          transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
        }

        &::before {
          content: '';
          position: absolute;
          top: -10px;
          left: 50%;
          width: 1px;
          height: 20px;
          opacity: 0;
          transform:  rotate(-45deg);
          transition: transform 0.4s, opacity 0.4s;
          transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
          background: $white;
        }
      }

      .nav-link-hover,
      .nav-link-main {
        display: block;
        white-space: nowrap;
        transition: .3s ease-in-out;
      }

      .nav-link-hover {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate3d(-50%, -100%, 0);
        opacity: 0;
        visibility: hidden;
      }

      .nav-link.active,
      .nav-link:hover {
        color: $white;
        background: transparent;
        &::before {
          opacity: 1;
          transform: rotate(45deg);
        }

        .nav-link-hover {
          opacity: 1;
          visibility: visible;
          transform: translate3d(-50%, 35px, 0);
        }
        .nav-link-main {
          transform: translate3d(0, 100%, 0);
          opacity: 0;
          visibility: hidden;
        }
      }
      
      [data-x-mode] & {
        .nav-link-hover {
          display: none;
        }
        .nav-link.active,
        .nav-link:hover {
          .nav-link-main {
            transform: none;
            opacity: 1;
            visibility: visible;
          }
        }
        
      }
    }

    &.tabs-line {
      .nav-tabs {
        border: 0;
        border-bottom: 4px solid $gray-200;
      }

      .nav-item {
        display: inline-block;

        &:not(:last-child) {
          margin-right: 52px;
        }
      }

      .nav-link {
        position: relative;
        padding: 0 0 15px 0;
        background-color: transparent;
        color: $gray-500;
        border: 0;

        &::after {
          content: '';
          position: absolute;
          bottom: -4px;
          left: 0;
          height: 4px;
          width: 0;
          background: $primary;
          transition: .22s ease;
        }
      }

      .nav-link.active,
      .nav-link:hover {
        color: $gray-300;
      }

      .nav-link.active {
        &::after {
          width: 100%;
        }
      }

      * + .tab-content {
        margin-top: 30px;
      }
    }
  }

  // 
  // Vertical tabs
  // 
  .tabs-vertical {
    display: flex;
    align-items: flex-start;

    .nav-tabs {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex-shrink: 0;
      max-width: 50%;
    }

    .nav-item {
      border: 0;
      width: 100%;
      text-align: left;
    }

    .nav-link.active,
    .nav-link:hover {
      box-shadow: 0 9px 21px 0 rgba(#1e1e1e, .13);
    }

    .tab-content {
      flex-grow: 1;
    }

    // Tabs corporate
    &.tabs-corporate {
      .nav-tabs {
        width: auto;
        min-width: 260px;
        border: 0;
      }

      .nav-item {
        margin: 0;
      }

      .nav-link {
        position: relative;
        padding: 17px 30px;
        border: 0;
        overflow: hidden;
        text-align: left;
      }

      .nav-item + .nav-item {
        margin-top: 2px;
      }

      .tab-content {
        padding: 0 0 0 30px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  //
  // Horizontal tabs
  // 
  .tabs-horizontal {

    // Tabs Corporate
    &.tabs-corporate {
      .tab-content {
        padding: 0;
      }
      > .tab-content {
        //max-height: 590px;
        min-height: 550px;
        overflow: hidden;
        padding-top: 30px;

        [data-x-mode] & {
          max-height: none;
          min-height: initial;
        }
      }
    }
  }

  // 
  // Vertical tabs
  // 
  .tabs-vertical {

    // Tabs corporate
    &.tabs-corporate {
      .tab-content {
        padding: 0 0 0 45px;
      }
    }
  }
}


// tabs gallery
.tabs-horizontal.tabs-gallery {
  > .nav-tabs {
    justify-content: center;
    position: relative;
    //max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
  }
  .nav-item {
    padding: 25px 5px;
    min-width: 300px;
    flex-basis: 33.3%;
    max-width: 33.3%;
    // Large ≥992px
    @include media-breakpoint-up(md) {
      padding: 25px 25px;
    }
  }
  .nav-link {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    padding: 25px;
    font-family: $font-family-sec-1;
    font-size: 24px;
    background: rgba($white, .1);
    
    img,
    span {
      position: relative;
      z-index: 1;
    }

    span {
      display: inline-block;
      max-width: 180px;
    }
    
    img {
      box-shadow: 13px 0 30px -7px rgba(0, 0, 0, 0.49); 
      //max-width: 120px;
      
      // Medium ≥768px
      @include media-breakpoint-up(xl) {
        max-width: 100%; 
      }
    }
    

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: -100%;
      left: 0;
      right: 0;
      bottom: 100%;
      opacity: 0; 
      z-index: 0;

      background: rgba(255, 255, 255, 0.13);
      background:linear-gradient(
              to bottom, rgba(255, 255, 255, 0.13) 0%,
              rgba(255, 255, 255, .13) 77%,
              rgba(255, 255, 255, 0.8) 77%,
              rgba(255, 255, 255, 0) 100%)
    } 

    * + * {
      margin-top: 30px;
    }
    // Large ≥992px
    @include media-breakpoint-up(xl) {
      flex-direction: row;
      justify-content: flex-start;
      padding: 0 10px 0 0;
      text-align: left;
      
      * + * {
        margin-left: 6%;
        margin-top: 0;
      }
    }
  }
  .nav-link {
    &:after,
    &:before {
      opacity: 1;
      //bottom: -150%;
      transition-property: bottom, opacity;
      transition-timing-function: ease;
    }
    &:after{
      transition-duration: 0.8s, 0.6s;
      bottom: -30%;
    }
    &:before{
      transition-delay: .1s;
      transition-duration: .6s, .4s;
      bottom: 0;
      transform: rotate(45deg);
    }
    //span:before {
    //  transform: rotate(135deg);
    //}
  }
  
  .nav-link:hover {
    background: rgba($white, .1);
    &:before{
      bottom: -50%;
    }
    &:after{
     opacity: .3;
    }
  }
  
  .nav-link.active,
  .nav-link:hover {
    //background: rgba($white, .1);
    transition-property: background;
    transition-delay: .5s;
    color: $white;  
  }

  > .tab-content {

    [data-x-mode] & {
      padding-top: 40px;
    }
  }

}
