/*
*
* Layout panel
*/

$width-mobile: 260px;
$width-desktop: 330px;

$scrollbar-padding: 2px;
$scrollbar-width: 16px;

.layout-panel-wrap {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: $zindex-layout-panel;

  &.active {
    .layout-panel {
      transform: translate3d(0, 0, 0);
    }

    .layout-panel-inner:after {
      box-shadow: $shadow-area-1;
    }
  }

  .layout-panel-toggle,
  .layout-panel-inner > * {
    pointer-events: auto;
  }
}

.layout-panel {
  width: auto;
  transform: translateX($width-mobile + $scrollbar-width);
  transition: .55s all ease;
}

// Scroll
.layout-panel-content {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  padding-right: $scrollbar-width;

  .mCSB_outside + .mCSB_scrollTools {
    right: 0;
  }

  .mCSB_scrollTools {
    width: $scrollbar-width;
    pointer-events: auto;
  }

  .mCSB_scrollTools .mCSB_dragger,
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: $scrollbar-width - $scrollbar-padding * 2;
  }

  .mCSB_draggerContainer {
    padding: $scrollbar-padding;
    background: $primary;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($white-invariable, .5);
    }
  }

  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
  .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: $white;
    border-radius: $form-input-border-radius;
  }

  .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
  .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: $white;
  }
}

// Inner content
.layout-panel-inner {
  position: relative;
  height: auto;
  min-height: 101vh;
  max-width: $width-mobile;
  padding: 20px 0 20px;
  color: $body-color;
  background: $white;
  margin-left: auto;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: -3px 0px 50px -2px rgba($black, 0);
    z-index: -10;
    transition: .33s all ease;
  }
  
  > * + * {
    margin-top: 20px;
  }
}

.layout-panel-controls {
  border-top: 1px solid $gray-100; 
  border-bottom: 1px solid $gray-100; 
  
  > * + * {
    border-top: 1px solid $gray-100;
  }
}

.mobile,
.tablet {
  &.rd-navbar-fixed-linked {
    .layout-panel-inner {
      padding-bottom: 90px;
    }
  }
}

.layout-panel-header {
  padding: 0 12px;

  .button {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  * + .layout-panel-element,
  * + .layout-panel-title {
    margin-top: 22px;
  }

  @include media-breakpoint-up(md) {
    * + .layout-panel-element,
    * + .layout-panel-title {
      margin-top: 30px;
    }
  }
}

@mixin layout-spacing($x: 5px, $y: $x) {
  padding-left: $x / 2;
  padding-right: $x / 2 - 1px;

  .isotope {
    position: relative;
    margin-bottom: -$y;
    transform: translateY(-$y);
  }

  .isotope-item {
    margin-top: $y;
    padding: 0 $x / 2;

    &::before {
      content: '';
      display: block;
    }
  }
}

.layout-panel-isotope-wrap {
  position: relative;
  z-index: 10;
  @include layout-spacing(10px, 10px);

  .isotope-item {
    flex-basis: 50%;
    max-width: 50%;
  }
  
  .isotope {
    position: static;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  
  .isotope-filters {
    padding: 0 5px;
  }
  
  .isotope-filters-list {
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      left: -100px;
      bottom: 0;
      right: -100px;
      border-bottom: 1px solid $gray-200;
    }

    li {
      display: inline-block;
      padding-left: 7px;
      padding-right: 7px;
      font: $font-weight-base 12px/18px $font-family-base;
      color: $gray-500;

      @include media-breakpoint-up(md) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    a {
      position: relative;
      display: inline-block;
      z-index: 2;
      padding-bottom: 14px;
      text-align: right;

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        background: $primary;
        transition: .34s;
        transform: translate(0, 8px);
        opacity: 0;
      }

      &,
      &:active,
      &:focus {
        color: inherit;
      }

      &.active,
      &:hover {
        color: $primary;
      }

      &.active {
        &::after {
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    }
  }
  
  * + .isotope { margin-top: 20px; }
  * + .isotope-filters { margin-top: 25px; }
}

.layout-panel-toggle {
  @include reset-button;
  position: fixed;
  top: 20vh;
  right: $width-mobile + $scrollbar-width;
  z-index: 10;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: $white;
  color: $black;
  text-align: center;
  box-shadow: -5px 1px 10px 0 rgba($black, .05);
  border-right: 0;
  transition: .55s all ease;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Material Design Icons';
    font-size: 16px;
    transition: .33s;
  }

  &::before {
    content: '\f582';
    opacity: 1;
  }

  &::after {
    content: '\f24c';
    opacity: 0;
  }

  &.active {
    &::before { 
      opacity: 0;
    }

    &::after { 
      opacity: 1;
    }
  }
}


* + .layout-panel-main { margin-top: 25px; }

.layout-panel-row {
  padding: 15px 10px;
  text-align: left;
  font-size: 14px;

  .jscolor {
    padding: 3px 6px;
    border-radius: $form-input-border-radius;
    border: none;
    box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, .3);
    text-align: center;
  }
  
  > * {
    display: inline-block;
    vertical-align: middle;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 1.2;
  }

  .toggle-custom-dummy {
    display: inline-block;
    margin-left: 16px;
  }

  > * + * { margin-left: 8px; }
}

html[dir='rtl'] .layout-panel-row {
  display: none;
}

.layout-panel-row-button-wrap {
  padding: 0;
  border: 0;
  .button {
    display: block;
    width: 100%;
    padding: 10px 20px;
  }
}

@include media-breakpoint-up(md) {
  .layout-panel-wrap {
    &.active {
      .layout-panel {
        transform: translate3d(0, 0, 0);
      }
    }

    .layout-panel {
      transform: translateX($width-desktop + $scrollbar-width);
    }

    .layout-panel-inner {
      max-width: $width-desktop;
    }

    .layout-panel-toggle {
      right: $width-desktop + $scrollbar-width;
    }
  }

  .layout-panel-toggle {
    width: 54px;
    height: 54px;
    line-height: 54px;

    &::before,
    &::after {
      font-size: 22px;
    }
  }

  &.ie-10,
  &.ie-11 {
    .layout-panel {
      transform: translateX($width-desktop + $scrollbar-width);
    }

    .layout-panel-toggle {
      right: $scrollbar-width;
    }

    .layout-panel-wrap {
      &.active {
        right: $scrollbar-width;

        .layout-panel-toggle {
          right: $width-desktop + $scrollbar-width * 2;
        }
      }
    }
  }
}

//
// Thumbnail Small
//
.thumbnail-small {
  position: relative;
  display: block;
  width: 100%;
  padding: 5px 5px 0;
  text-align: center;
  transition: 0.3s ease;
  color: $gray-700;
  background-color: $gray-100;

  &:hover {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }
}

.thumbnail-small-image,
.thumbnail-small-hover-image {
  img {
    width: 100%;
    height: auto;
  }
}

.thumbnail-small-image {
  position: relative;
  overflow: hidden;
  max-height: 105px;
}

.thumbnail-small-hover-image {
  display: none;
}

.thumbnail-small-caption {
  position: relative;
  padding: 8px 8px;
}

.thumbnail-small-header {
  position: relative;
  z-index: 1;
  font: 400 13px/18px $font-family-base;
  color: inherit;
  
  .brand {
    max-width: 90%;
  }
}

@include media-breakpoint-up(lg) {
  html:not(.tablet):not(.mobile) {
    .thumbnail-small-hover-image {
      display: block;
      position: absolute;
      left: -10px;
      top: 50%;
      z-index: 11;
      width: 380px * 0.9;
      height: 280px * 0.9;
      padding: 7px;
      background: $white;
      transform: translate3d(-120%, -60%, 0);
      box-shadow: -3px 0px 50px -2px rgba($black, .14);
      opacity: 0;
      pointer-events: none;
      transition: .33s all ease;

      img {
        position: relative;
        max-width: 100%;
        max-height: 100%;
      }

      &::before {
        content: '';
        position: absolute;
        right: -11px;
        top: 50%;
        z-index: -1;
        width: 24px;
        height: 24px;
        transform: translate3d(0, -50%, 0) rotate(45deg);
        background: $white;
        box-shadow: -3px 0px 45px -2px rgba($black, 0.13);
      }
    }

    .thumbnail-small {
      &:hover {
        .thumbnail-small-caption::after {
          bottom: 0;
          opacity: 1;
        }

        .thumbnail-small-header {
          color: $white;
        }

        .thumbnail-small-image::after {
          opacity: 0;
        }

        .thumbnail-small-hover-image {
          z-index: 12;
          opacity: 1;
          transform: translate(-100%, -60%);
        }
      }
    }
  }
}

.theme-switcher-group-item {
  position: relative;
  overflow: hidden;
  @include reset-button();
  width: 30px;
  height: 30px;
  font-size: 0;
  cursor: pointer;
  background: $primary;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    content: '\f222';
    font-family: "Material Design Icons";
    font-size: 22px;
    transition: .22s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    color: $white;
    text-shadow: 0 0 6px rgba($black, .2);
    z-index: 2;
  }

  &.active-theme {
    &::before {
      opacity: 1;
      visibility: visible;
    }

  }
} 

.theme-switcher-group-item[data-theme-name='default'] {
  @import "../color-scheme/colors-default";
  background: $primary;
}

.theme-switcher-group-item[data-theme-name='style-1'] {
  @import "../color-scheme/colors-1";
  background: $primary;
}