/*
*
* Owl Carousel
*/

.owl-carousel .animated {
  -webkit-animation-duration: 600ms;
  animation-duration: 600ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  transition: scale 100ms ease;
  &:before {
    content: '\f144';
  }
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/*
 * Owl Navigation
 */

.owl-nav {
  &.disabled {
    display: none;
  }
}

.owl-prev, .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font: 400 46px/50px 'FontAwesome';
  color: $gray-300;

  &:hover {
    color: $primary;
  }
}

.owl-prev {
  left: 0;

  &::before {
    content: '\f104';
  }
}

.owl-next {
  right: 0;

  &::before {
    content: '\f105';
  }
}

/*
 * Owl Pagination
 */

.owl-dots {
  text-align: center;
  margin-top: 10px;
}

.owl-dot {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid $gray-300;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: .2s;

  &:hover,
  &:focus {
    background-color: $gray-300;
  }

  &.active {
    background-color: $gray-300;
  }
}

.owl-carousel.owl-carousel-light-dots {
  .owl-dot {
    border-color: $white;

    &:hover,
    &:focus {
      background-color: $white;
    }

    &.active {
      background-color: $white;
    }
  }
}

//owl gallery
.gallery-wrap {

  [class*='dots-custom-'] {
    border-top: 3px solid $white;
    padding: 20px 0;
  }
  
  .gallery-wrap-inner {
    & + * {
      margin-top: 20px;
    } 
  }

  .owl-dot {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin: 3px;
    opacity: .2;
    transition: .3s ease-in-out;
    &:before{
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      background-color: $white;
      border-radius: 100%;
      opacity: 0;
      transition: .3s ease-in-out;
    }
  }

  .owl-dot.active,
  .owl-dot:hover {
    opacity: 1;
    background-color: transparent;
    &:before{
      opacity: 1;
    }
  }
  
  // Large ≥992px
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    
    .gallery-wrap-inner:first-of-type{
      flex-shrink: 0;
      padding-right: 30px;
      max-width: 200px;
      overflow: hidden;
      > * {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .gallery-wrap-inner:last-of-type{
      flex-grow: 1;
    }
    .gallery-wrap-inner {
      & + * {
        margin-top: 0;
      }
    }
    
    [class*='dots-custom-'] {
      min-width: 170px;
      max-width: 170px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      border-top: 3px solid $white;
      padding: 30px 20px;
      margin-top: 25px;
    }
    .owl-dot {
      width: 36px;
      height: 36px;
    }
  }
}

.owl-gallery {
  //width: auto;
  .owl-stage-outer { 
    
    //max-width: 970px;
    width: 100%;
    //margin-left: auto;
    //margin-right: auto;
  }
  
  .gallery-item {
    //border-radius: 85px;
    //overflow: hidden;
  }
  
  // Large ≥992px
  @include media-breakpoint-up(lg) {
    max-width: 730px; 
  }
  // Extra large ≥1200px
  @include media-breakpoint-up(xl) {
    max-width: 100%; 
  } 
}