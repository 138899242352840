/*
*
* Text styling
*/

// Text styles
.text-italic {
  font-style: italic;
}

.text-normal {
  font-style: normal;
}

// Text decoration
.text-underline {
  text-decoration: underline;
}

.text-strike {
  text-decoration: line-through;
}

// Text weight
.font-weight-thin {
  font-weight: 100;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-regular {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-sbold {
  font-weight: 600;
}

.font-weight-ubold {
  font-weight: 900;
}

// Text spacing
.text-spacing-0 {
  letter-spacing: 0;
}

.text-primary {
  color: $primary !important;
}