/*
*
* Progress Bars
*/

//
// Linear Progress Bars
//

.progress-linear {
  position: relative;
  text-align: left;

  .progress-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include spacing(10px);
  }

  .progress-bar-linear-wrap {
    height: 10px;
    background: $gray-200;
  }

  .progress-bar-linear {
    width: 0;
    height: inherit;
    background: $primary;
    transition: .5s all ease-in-out;
  }

  .progress-value {
    font-weight: 700;
    color: $gray-300;
    &::after {
      content: "%";
    }
  }
}
* + .progress-bar-linear-wrap { margin-top: 8px; }
.progress-linear + .progress-linear { margin-top: 15px; }

@include media-breakpoint-up(lg) {
  .progress-linear + .progress-linear { margin-top: 25px; }
}
