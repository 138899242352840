/**
*
* Gallery item
*/

.gallery-item {
  position: relative;
  display: inline-block;
  
  img {
    width: 100%;
  }
  
  .caption {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px;
    transform: translateX(50%);

    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 40px;
    height: 40px;
    
    //border-radius: 50%;
    background: rgba($black, .3);
    color: $white;
    
    opacity: 0;
    visibility: hidden;
    transition: $transition-base;
    
    [data-x-mode] & {
      opacity: 1;
      visibility: visible;
    }
    
    .icon {
      font-size: 20px;
      line-height: .6; 
    }
    
    // Large ≥992px
    @include media-breakpoint-up(lg) {
      //width: 100px;
      //height: 100px;

      .icon {
        //font-size: 60px;
      }
    }
  }

  &:hover{
    .caption {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }
}