/*
*
* Thumbnails
*/

// 
// Table of Contents
// 
// Figure Light
//


//
// Figure Light
//
.figure-light figcaption {
  padding-top: 10px;
  color: $gray-400;
}